import { memo, useEffect, useState } from "react";
import classes from "./ManagementList.module.scss";
import EditTask from "components/ui/icons/EditTask";
import { useApi } from "hooks/useApi/useApi";
import { useSearchParams } from "react-router-dom";
import EmptyImage from "assets/img/empty-image.png";
import { IManagementEmployeFull, ManagementEmployeeType } from "types";
import Skeleton from "react-loading-skeleton";
import Paper from "components/ui/paper/Paper";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import Modal from "components/common/modal/Modal";
import { ManegementForm } from "components/management/ManegmentForms/ManegementForm/ManegementForm";
import CloseModal from "components/ui/icons/CloseModal";

/* eslint-disable react/display-name */
const ManagementListItem = memo(
  ({
    item,
    onEdit,
  }: {
    item: IManagementEmployeFull;
    onEdit: (data: null | IManagementEmployeFull) => void;
  }) => {
    const handlerEdit = () => onEdit(item);
    const timeStart = item.work_start_time.split(":");
    const timeEnd = item.work_end_time.split(":");
    return (
      <div className={classes.row}>
        <div className={classes.name}>
          <div className={classes.image}>
            <img src={item.photo ? item.photo : EmptyImage} alt="avatar" />
          </div>
          {item.first_name} {item.last_name}
        </div>
        <div className={classes.position}>
          {item.position_name}
        </div>
        <div className={classes.competence}>{item.position_grade}</div>
        <div className={classes.department}>{item.department}</div>
        <div className={classes.hours}>c {`${timeStart[0]}:${timeStart[1]}`}- {`${timeEnd[0]}:${timeEnd[1]}`}</div>
        <div className={classes.edit} onClick={handlerEdit} role="button">
          <EditTask clazz={classes.editIcon} />
          Редактировать
        </div>
      </div>
    );
  },
);
/* eslint-disable react/display-name */
const ManagementListItemMobile = memo(
  ({
    item,
    onEdit,
  }: {
    item: IManagementEmployeFull;
    onEdit: (data: null | IManagementEmployeFull) => void;
  }) => {
    const handlerEdit = () => onEdit(item);
    const timeStart = item.work_start_time.split(":");
    const timeEnd = item.work_end_time.split(":");
    return (
      <div className={classes.row}>
        <div className={`${classes.group} ${classes.pb8}`}>
          <div className={classes.name}>
            <div className={classes.image}>
              <img src={item.photo ? item.photo : EmptyImage} alt="avatar" />
            </div>
            {item.first_name} {item.last_name}
          </div>
          <div className={classes.edit} onClick={handlerEdit} role="button">
            <EditTask clazz={classes.editIcon} />
            <span className={classes.editLabel}>Редактировать</span>
          </div>
        </div>
        <div className={classes.group}>
          <div className={classes.position}>
            {item.position_name}
          </div>
          <div className={classes.competence}>{item.position_grade}</div>
        </div>
        <div className={classes.group}>
          <div className={classes.department}>{item.department}</div>
          <div className={classes.hours}>c {`${timeStart[0]}:${timeStart[1]}`}- {`${timeEnd[0]}:${timeEnd[1]}`}</div>
        </div>
      </div>
    );
  },
);

const ManagementList = () => {
  const [data, setData] = useState<ManagementEmployeeType[] | null>(null);
  const [searchParams] = useSearchParams();
  const { width = window.innerWidth } = useWindowSize();
  const [modalData, setModalData] = useState<null | IManagementEmployeFull>(
    null,
  );
  const { apiGet } = useApi();

  const onHideModal = () => setModalData(null);
  const onChangeModalData = (data: null | IManagementEmployeFull) => {
    setModalData(data);
  };

  useEffect(() => {
    const getManagementList = async () => {
      if (searchParams.get("position")) {
        searchParams.set("role", searchParams?.get("position") || "");
        searchParams.delete("position");
      }
      const res = await apiGet(
        `${process.env.REACT_APP_API_URL}api/employee/employees`,
        searchParams,
      );

      res.data?.data && setData(res.data.data);
    };
    getManagementList();
  }, [searchParams]);

  return (
    <>
      <div className={classes.container}>
        {data ? (
          data.map((item) => (
            <Paper
              key={item.id}
              cls={{
                paddingRoot: classes.paddingPaper,
              }}
            >
              {width >= 1220 ? (
                <ManagementListItem
                  item={item as unknown as IManagementEmployeFull}
                  onEdit={onChangeModalData}
                />
              ) : (
                <ManagementListItemMobile
                  item={item as unknown as IManagementEmployeFull}
                  onEdit={onChangeModalData}
                />
              )}
            </Paper>
          ))
        ) : (
          <Skeleton
            count={6}
            style={{
              height: "80px",
              display: "block",
              width: "100%",
            }}
          />
        )}
      </div>
      <Modal
        hide={onHideModal}
        isShown={!!modalData}
        closeIcon={<CloseModal />}
        clsx={{
          close: classes.modalCloase,
          body: classes.modal,
        }}
      >
        <ManegementForm initData={modalData} labelBtn="Сохранить"/>
      </Modal>
    </>
  );
};

export default ManagementList;
