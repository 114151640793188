import { useState } from "react";
import { IManagementEmployeFull } from "types";
import classes from "../ManegementForms.module.scss";
import FormUserData from "../components/FormUserData";
import FormUserKnowledge, {
  IItemUserKnowledgeSelect,
  IUserKnowledgeType,
} from "../components/FormUserKnowledge";
import FormUserSach from "../components/FormSach";

type Form = "user-data" | "user-knowledge" | "user-sach";

export const ManegementForm = ({
  labelBtn = "Создать",
  initData,
}: {
  labelBtn?: string;
  initData?: IManagementEmployeFull | null;
}) => {
  const [selectedUserKnowledge, setSelectedUserKnowledge] =
    useState<IUserKnowledgeType>({});
  const [formTab, setFormTab] = useState<Form>("user-data");

  const onUpdateSelected = (
    value: IItemUserKnowledgeSelect,
    options?: { delete?: boolean },
  ) => {
    const items = { ...selectedUserKnowledge };
    if (options && options.delete) {
      delete items[value.id];
      setSelectedUserKnowledge(items);
      return;
    }

    items[value.id] = value;
    setSelectedUserKnowledge(items);
  };

  const FORM_TABS = {
    "user-data": <FormUserData data={initData || undefined} labelBtn={labelBtn} />,
    "user-knowledge": (
      <FormUserKnowledge
        labelBtn={labelBtn}
        selected={selectedUserKnowledge}
        updateSelected={onUpdateSelected}
      />
    ),
    "user-sach": initData?.id && <FormUserSach labelBtn={labelBtn} userId={initData.id} />,
  };

  return (
    <div className={classes.body}>
      <div className={`${classes.btnGroup} ${classes.btnGroupPadding}`}>
        <button
          className={`${classes.btnGroupItem} ${
            formTab === "user-data" ? classes.active : ""
          }`}
          onClick={() => setFormTab("user-data")}
        >
          Данные сотрудника
        </button>
        <button
          className={`${classes.btnGroupItem} ${
            formTab === "user-knowledge" ? classes.active : ""
          }`}
          onClick={() => setFormTab("user-knowledge")}
        >
          Область знаний сотрудника
        </button>
        <button
          className={`${classes.btnGroupItem} ${
            formTab === "user-sach" ? classes.active : ""
          }`}
          onClick={() => setFormTab("user-sach")}
        >
          САЧ сотрудника
        </button>
      </div>
      {FORM_TABS[formTab]}
    </div>
  );
};
